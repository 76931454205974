body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.body,.html{
  font-size: 16px !important;
  font-family: Open Sans !important;
  color: #0C2637 !important;
}
  
.container, .error-container {
  flex-direction: column;
  flex: 1 1 auto;
  height: calc(100vh - 100px);;
  width: 100vh;
  margin:0;
  min-width: 100%; 
  display:flex; 
  flex-direction: vertical; 
  padding: 0px !important;
  scroll-behavior: smooth;
}

.headerLogo{
  /*width: 220px;*/
  height: 50px;
}

.headerLogoSeparator{
  border-right: 1px solid #FFF;
  padding-right: 20px;
}

.tabImage{
  width: 26px;
  height: 21px;
  margin-right: 5px;
}

.bg-headerBlue{
  background-color:#0d6cac; 
  color: #FFF;
  font-weight: 600;
  font-size: 1.2rem;
}
.bg-headerWhite{
  background-color:#FFF; 
}

.navbar{
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  vertical-align: middle !important;
}

.nav-tabs{
  border: 0px !important;
}
/*.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link 
.navbar-dark .navbar-nav
*/
  .nav-link {
  padding-right: 1rem;
  color: #0d6cac !important;
  border: 0px !important;
  margin: 0 !important;
  border-radius: 0px !important;
  display: flex !important;
  border-left: 1px solid #FFF;
}

.nav-link:hover {
  cursor: 'pointer';
  background-color: #EEE !important;
  border: 0px !important;
}

  .nav-link.active{
  color: #0d6cac !important;
  background-color: #EEE !important;
  border: 0px !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.error-container {
  background: #ECEFF1;
  color: rgba(0,0,0,0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.error-container #message {
  background: white;
  max-width: 360px;
  margin: 100px auto 16px;
  padding: 32px 24px 16px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.error-container #message h2 {
  color: #0d6cac;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 8px;
}

.error-container #message h3 {
  font-size: 22px;
  font-weight: 300;
  color: rgba(0,0,0,0.6);
  margin: 0 0 16px;
}

.error-container #message p {
  line-height: 140%;
  margin: 16px 0 24px;
  font-size: 14px; 
}

.error-container #message code {
  color: #0d6cac;
}

@media (max-width: 600px) {
  .error-container #message {
    margin-top: 0;
    background: white;
    box-shadow: none;
  }
}